function apply(xhr) {
    var /** type {string} */
        screen,
        /** type {string} */
        prevScreen;
    if ("true" === document.body.getAttribute("data-uic")) {
        /* Включено */
        if (OK.getCurrentDesktopModelId) {
            screen = OK.getCurrentDesktopModelId();
            if (OK.Layers && OK.Layers.isAnyLayerOpened()) {
                prevScreen = screen;
                screen = OK.Layers.getTopLayerId();
            }
            if (screen) {
                xhr.setRequestHeader("OK-Screen", screen);
            }
            if (prevScreen) {
                xhr.setRequestHeader("OK-PrevScreen", prevScreen)
            }
        }
    }
}

export default { apply };

export { apply };
